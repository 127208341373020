<template>
  <gw-dialog v-model="drawer" hide-actions>
    <template #title>
      <v-toolbar color="transparent" flat>
        <v-toolbar-title v-text="obModel?.label" />
        <v-spacer />
        <field-preview
          v-if="sInvoiceDate"
          :label="$t('invoice.date')"
          :value="sInvoiceDate"
        />
        <field-preview v-if="showRate" :label="$t('rate')" :value="rate" />
        <field-preview
          v-if="currency"
          :label="$t('currency')"
          :value="currency.code"
        />
        <field-preview v-if="book" :label="$t('account.book')" :value="book" />
      </v-toolbar>
      <v-divider />
    </template>

    <v-form v-if="obModel" class="fill-height" @submit.prevent>
      <gw-form-observer
        :loading="isLoading"
        hide-top-actions
        @cancel="cancel"
        @save="save"
      >
        <settings v-model="obModel" @save="save" />

        <template v-if="obInvoice?.id" #bottom-actions>
          <v-btn
            :disabled="valid"
            class="mr-2"
            color="primary"
            @click="onAddPosition"
          >
            <icon-add class="mr-2" />
            {{ $t("add.item") }}
          </v-btn>

          <invoice-number-btn
            :invoice="obInvoice"
            :label="$t('open.invoice')"
            button
          />
        </template>
      </gw-form-observer>
    </v-form>
  </gw-dialog>
</template>

<script lang="ts">
import { Component, Mixins, PropSync } from "vue-property-decorator";
import AccountingentryinvoicesMixin from "@/modules/accountingentryinvoices/mixins/AccountingentryinvoicesMixin";
import SheetDrawer from "@/layout/components/main/SheetDrawer.vue";
import Settings from "@/modules/accountingentryinvoices/components/tabs/Settings.vue";
import GwDialog from "@/components/common/GwDialog.vue";
import type { CurrencyData } from "@planetadeleste/vue-mc-shopaholic";
import FieldPreview from "@/components/common/FieldPreview.vue";
import { number } from "mathjs";
import { filter, get, has, isFunction } from "lodash";
import type {
  AccountingEntryInvoice,
  AccountingEntryInvoicePositionData,
  InvoiceData,
} from "@planetadeleste/vue-mc-gw";
import InvoiceNumberBtn from "@/modules/invoices/components/InvoiceNumberBtn.vue";
import { round } from "@/plugins/helpers";
import useMath from "@/composables/math";

const { math, sumBy } = useMath();

@Component({
  components: {
    InvoiceNumberBtn,
    FieldPreview,
    GwDialog,
    SheetDrawer,
    Settings,
  },
})
export default class AccountingentryinvoicesForm extends Mixins(
  AccountingentryinvoicesMixin
) {
  @PropSync("open", { type: Boolean, default: false }) drawer!: boolean;
  forceSave = true;
  tab = 0;
  tabItems: string[] = ["settings"];

  get modelExists() {
    return (
      !!this.obModel && has(this.obModel, "get") && isFunction(this.obModel.get)
    );
  }

  get currency(): CurrencyData | undefined {
    return this.modelExists ? this.obModel?.get("currency") : undefined;
  }

  get rate() {
    return this.modelExists ? number(this.obModel?.get("rate", 0)) : 0;
  }

  get showRate(): boolean {
    return (
      this.currency?.code !== "UYU" &&
      !!this.rate &&
      this.rate !== 1 &&
      this.rate !== 1.0
    );
  }

  get positions(): Partial<AccountingEntryInvoicePositionData>[] {
    return this.modelExists ? this.obModel?.get("positions", []) : [];
  }

  get debit(): number {
    return round(sumBy(this.positions, "debit"));
  }

  get credit(): number {
    return round(sumBy(this.positions, "credit"));
  }

  get valid() {
    return (
      !filter(
        this.positions,
        (obItem: AccountingEntryInvoicePositionData) => !obItem.valid
      ).length && math.subtract(this.credit, this.debit) === 0
    );
  }

  get obInvoice(): InvoiceData | undefined {
    return this.modelExists ? this.obModel?.get("invoice") : undefined;
  }

  get sInvoiceDate(): string | undefined {
    return this.obInvoice
      ? this.$dayjs(get(this.obInvoice, "created_at")).format("L")
      : undefined;
  }

  get book() {
    let sBookLabel = null;
    switch (this.obModel?.book) {
      case "V":
        sBookLabel = "sales";
        break;
      case "C":
        sBookLabel = "purchases";
        break;
      case "I":
        sBookLabel = "revenues";
        break;
      case "E":
        sBookLabel = "expenses";
        break;
      case "P":
        sBookLabel = "general.journal";
        break;
    }

    return sBookLabel ? this.$t(sBookLabel) : undefined;
  }

  onAddPosition() {
    if (!this.modelExists) {
      return;
    }

    const arPositions = [...this.positions];
    const obPositionData: Partial<AccountingEntryInvoicePositionData> = {
      debit: 0,
      credit: 0,
      accounting_entry_invoice_id: this.obModel?.id,
      sort_order: arPositions.length + 1,
      valid: false,
    };
    arPositions.push(obPositionData);
    this.obModel?.set("positions", arPositions);
  }

  onRegisterEvents() {
    this.addEvent(
      `${this.sModelName}.before.sync`,
      (obModel: AccountingEntryInvoice) => {
        obModel.unset(["invoice", "currency", "issues"]);
      }
    );
  }
}
</script>
