<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="12">
        <data-table
          :headers="headers"
          :items="items"
          :last-page="serverLastPage"
          :loading="bLocalLoading"
          :options="pagination"
          :total="serverItemsLength"
          btn-action-item-key-id="id"
          btn-action-path="accountingentryinvoices"
          hide-btn-action-view
          is-route-name
          @delete="deleteItem"
          @update:sort-by="onSortBy"
          @update:sort-desc="onSortDesc"
          @update:options="onPagination"
          @update:page="onPage"
        >
          <template v-slot:[`item.active`]="{ item }">
            <active-icon :active="item.active" />
          </template>

          <template #[`item.label`]="{ item }">
            <v-btn
              :to="{
                name: 'accountingentryinvoices.update',
                params: { id: item.id },
              }"
              color="primary"
              text
            >
              <span class="text-truncate w-max-350" v-text="item.label" />
            </v-btn>
          </template>

          <template #[`item.invoice`]="{ item }">
            <invoice-number-btn :invoice="item.invoice" />
          </template>

          <template #[`item.created_at`]="{ item }">
            {{ $dayjs(item.invoice.created_at).format("L") }}
          </template>

          <template #[`item.issues`]="{ item }">
            <issues-table :items="item.issues" />
          </template>

          <template #[`item.actions`]="{ item }">
            <module-actions :value="item.id" @delete="deleteItem" />
          </template>
        </data-table>
      </v-col>
    </v-row>
    <accountingentryinvoices-form
      :key="$route.params.id"
      v-model="obItem"
      :open.sync="displayForm"
    />
  </v-col>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import AccountingentryinvoicesMixin from "@/modules/accountingentryinvoices/mixins/AccountingentryinvoicesMixin";
import ActiveIcon from "@/components/common/ActiveIcon.vue";
import AccountingentryinvoicesForm from "@/modules/accountingentryinvoices/components/Form.vue";
import type { DataTableHeader } from "@/mixins/DataTableMixin";
import type { AccountingEntryInvoiceData } from "@planetadeleste/vue-mc-gw";
import { map, set } from "lodash";
import IssuesTable from "@/modules/accountingentryinvoices/components/IssuesTable.vue";
import InvoiceNumberBtn from "@/modules/invoices/components/InvoiceNumberBtn.vue";

@Component({
  components: {
    InvoiceNumberBtn,
    IssuesTable,
    ActiveIcon,
    AccountingentryinvoicesForm,
  },
})
export default class AccountingentryinvoicesList extends Mixins(
  AccountingentryinvoicesMixin
) {
  isList = true;

  get items() {
    return map(
      this.obCollection.getModelList() as AccountingEntryInvoiceData[],
      (obItem) => {
        set(obItem, "css", obItem.valid ? "green lighten-4" : "red lighten-5");

        return obItem;
      }
    );
  }

  onMounted() {
    const arHeaders: DataTableHeader[] = [
      { text: "date", value: "created_at" },
      { text: "accounting.description", value: "label", width: 200 },
      { text: "invoice", value: "invoice" },
      { text: "accounting.debit", value: "debit", align: "end" },
      { text: "accounting.credit", value: "credit", align: "end" },
      { text: "accounting.balance", value: "balance", align: "end" },
      { text: "accounting.issues", value: "issues" },
    ];
    this.setDTHeaders(arHeaders);
    this.addDTActionsHeader();

    this.index();
  }
}
</script>
